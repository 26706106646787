import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";

const PublisherPassword = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    Password: "",
    ComfirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(formData.Password === formData.ComfirmPassword)
      {
        await axiosInstance.post(
          `Publisher/UpdatePassword/${id}`,formData
        );
        alert("Password Updated Successfully");
        setFormData({
          Password: "",
          ComfirmPassword: "",
        })
      }
      else{
        alert("Password and Confirm Password must be same");
      }
  
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }

      console.error("Error creating publisher:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="fs-13px">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/publisher"> Publisher</Link>
              </li>
              <li className="breadcrumb-item Active"> Update Password </li>
            </ul>
            <h1 className="page-header mb-0">Update Password</h1>
          </div>
          <div className="ms-auto">
            <button
              type="submit"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
              Submit
            </button>
          </div>
        </div>
        <div className="card border-0">
          <ul className="nav nav-tabs nav-tabs-v2 px-3">
            <li className="nav-item me-2">
              <Link to={"/updatePublisher/" + id} className="nav-link px-2">
                General
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link px-2 active">Password</Link>
            </li>
            <li className="nav-item me-2">
              <Link to={"/publisherWeb/" + id} className="nav-link px-2">
                Websites
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link to={"/publisherButton/" + id} className="nav-link px-2 ">
                Buttons
              </Link>
            </li>
            <li className="nav-item me-2">
              <Link to={"/publisherReport/" + id} className="nav-link px-2">
                Report
              </Link>
            </li>
          </ul>
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="form-floating mb-15px">
                <input
                  type="password"
                  className="form-control h-45px fs-13px"
                  placeholder="Password"
                  id="Password"
                  name="Password"
                  value={formData.Password}
                  onChange={handleChange}
                />
                <label
                  htmlFor="Password"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Password
                </label>
              </div>

              <div className="form-floating mb-15px">
                <input
                  type="password"
                  className="form-control h-45px fs-13px"
                  placeholder="Confirm Password"
                  id="ComfirmPassword"
                  name="ComfirmPassword"
                  value={formData.ComfirmPassword}  
                  onChange={handleChange}
                />
                <label
                  htmlFor="confirmpassword"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Confirm Password
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PublisherPassword;
