import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";

const Publisher = () => {
  const [publishers, setPublishers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);

  useEffect(() => {
    FetchStaff();
  }, []);

  const updateStatus = async (id) => {
    try {
      await axiosInstance.post(`Publisher/ChangeStatus/${id}`);
      await FetchStaff();
    }
    catch (error) {
      console.log(error);
    }
  }

  const FetchStaff = async () => {
    try {
      const response = await axiosInstance.get("Publisher/GetAll");
      const users = response.data;
      console.log(users);
      setPublishers(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate} ${formattedTime}`;
  }

  // Calculate the data for the current page
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = publishers.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(publishers.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DeleteUser = async (id) => {
    try {
      await axiosInstance.delete(`Publisher/DeletePublisher/${id}`);
      const updatedStaffs = publishers.filter((staff) => staff.id !== id);
      setPublishers(updatedStaffs);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Publisher</li>
          </ul>
          <h1 className="page-header mb-0">Publisher</h1>
        </div>
        <div className="ms-auto">
          <Link
            to="/createPublisher"
            className="btn btn-success btn-rounded px-4 rounded-pill"
          >
            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
            Create Publisher
          </Link>
        </div>
      </div>

      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                    style={{ zIndex: 10 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control px-35px bg-light"
                    placeholder="Search orders..."
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Last Login</th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((staff) => (
                    <tr key={staff.id}>
                      <td>{staff.FullName}</td>
                      <td>{staff.Email}</td>
                      <td onClick={() => updateStatus(staff.id)}>
                        {staff.Status ? (
                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Active
                          </span>
                        ) : (
                          <span className="badge border border-danger text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Pending
                          </span>
                        )}
                      </td>
                      <td>{formatDateTime(staff.createdAt)}</td>
                      <td>{formatDateTime(staff.createdAt)}</td>
                      <td>
                        <span>
                          <Link to={"/updatePublisher/" + staff.id}>
                            <i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
                          </Link>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          <i
                            onClick={() => DeleteUser(staff.id)}
                            className="fa fa-trash fs-13px fa-fw me-5px"
                          ></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing{" "}
                {publishers.length === 0
                  ? indexOfFirstEntry
                  : indexOfFirstEntry + 1}{" "}
                to{" "}
                {indexOfLastEntry > publishers.length
                  ? publishers.length
                  : indexOfLastEntry}{" "}
                of {publishers.length} entries
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${index + 1 === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publisher;
