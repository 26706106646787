import React from "react";
import { AppSettings } from "../../config/app-settings.js";
import axios from "axios";
import { enqueueSnackbar } from "notistack";


class Login extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      redirect: false,
      error: null, // Adding an error state to handle login errors
    };
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass("p-0");
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass("");
  }

  async handleSubmit(event) {
    event.preventDefault();

    const email = event.target.emailAddress.value;
    const password = event.target.password.value;

    try {
      let API_URL = "";
      if (process.env.REACT_APP_Environment == 'development')
        API_URL = process.env.REACT_APP_API_URL;
      else
        API_URL = process.env.REACT_APP_API_URL_Production;
 
      const response = await axios.post(API_URL + "User/Login", {
        UserName: email,
        Password: password,
      });
      const { token, User } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(User));
      window.location.reload();
      this.setState({ redirect: true });
    } catch (error) {
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      })
    }
  }

  render() {
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div
            className="news-image"
            style={{
              backgroundImage: "url(/assets/img/login-bg/login-bg-11.jpg)",
            }}
          ></div>
          <div className="news-caption">
            <h4 className="caption-title">
              <b>Click Per </b> Install
            </h4>
            <p>
              Click Per Install is a Trending ad network in pay per install industry.
              We promise to give rates overall with our dynamic system.
            </p>
          </div>
        </div>
        <div className="login-container">
          <div className="login-header mb-30px">
            <div className="brand">
              <div className="d-flex align-items-center">
                <span className="logo"></span>
                <b>Click Per  </b> Install
              </div>
              <small>Admin Panel</small>
            </div>
            <div className="icon">
              <i className="fa fa-sign-in-alt"></i>
            </div>
          </div>
          <div className="login-content">
            <form onSubmit={this.handleSubmit} className="fs-13px">
              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="User Name"
                  id="emailAddress"
                />
                <label
                  htmlFor="emailAddress"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  User Name
                </label>
              </div>
              <div className="form-floating mb-15px">
                <input
                  type="password"
                  className="form-control h-45px fs-13px"
                  placeholder="Password"
                  id="password"
                />
                <label
                  htmlFor="password"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Password
                </label>
              </div>
              <div className="form-check mb-30px">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="rememberMe"
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div>
              <div className="mb-15px">
                <button
                  type="submit"
                  className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                >
                  Sign me in
                </button>
              </div>
              <hr className="bg-gray-600 opacity-2" />
              <div className="text-gray-600 text-center text-gray-500-darker mb-0">
                &copy; ClickPerInstall. All Rights Reserved
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
