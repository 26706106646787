import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UpdateLD = () => {
  const history = useHistory();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    AdName: "",
    AdUrl: "",
    AdKeyword: "",
    Status: true,
  });

  const FetchLandingDomainDetail = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`LandingDomain/Get/${id}`);
      const domain = response.data;
      setFormData({
        AdName: domain.AdName || "",
        AdUrl: domain.AdUrl || "",
        AdKeyword: domain.AdKeyword || "",
        Status: domain.Status || true,
      });
    } catch (error) {
      console.error("Error fetching landing domain details:", error);
    }
  }, [id]);
  
  useEffect(() => {
    FetchLandingDomainDetail();
  }, [FetchLandingDomainDetail]);

 


  const handleChange = (e) => {
    const { id, value, type, checked, name } = e.target;
    if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value === "true",
      });
    } else {
      setFormData({
        ...formData,
        [id]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`LandingDomain/Update/${id}`, formData);
      history.push("/landingDomain");
    } catch (error) {
      console.error("Error creating Landing Domain:", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="fs-13px">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/staff"> Staff</Link>
              </li>
              <li className="breadcrumb-item Active"> Update Landing Domain </li>
            </ul>
            <h1 className="page-header mb-0">Update Landing Domain</h1>
          </div>
          <div className="ms-auto">
            <button
              type="submit"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
              Submit
            </button>
          </div>
        </div>
        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Ad Name"
                  id="AdName"
                  value={formData.AdName}
                  onChange={handleChange}
                />
                <label
                  htmlFor="AdName"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                 Ad Name
                </label>
              </div>

              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Ad Url"
                  id="AdUrl"
                  value={formData.AdUrl}
                  onChange={handleChange}
                />
                <label
                  htmlFor="AdUrl"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                 Ad Url
                </label>
              </div>

              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Ad Keyword"
                  id="AdKeyword"
                  value={formData.AdKeyword}
                  onChange={handleChange}
                />
                <label
                  htmlFor="AdKeyword"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Ad Keyword
                </label>
              </div>

              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="col-md-12 d-flex align-items-center">
                    <div className="form-check mb-15px me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Status"
                        checked={formData.Status}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="Status">
                        Status
                      </label>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateLD;
