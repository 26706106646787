import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import moment from 'moment-timezone'

const YesterdayTracking = () => {
	const [report, setReport] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [entriesPerPage] = useState(10);

	useEffect(() => {
		FetchReport();
	}, []);

	const FetchReport = async () => {
		try {
			const Date = moment.tz("Asia/Karachi").subtract(1, 'days').format('YYYY-MM-DD');
			const response = await axiosInstance.get(`/ClickReport/AllPublisherReportByDate/${Date}`);
			const todayReport = response.data;
			setReport(todayReport);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				window.location.reload();
			}
			console.error("Error fetching staff:", error);
		}
	};

	function formatDateTime(isoString) {
		const date = new Date(isoString);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");

		const formattedDate = `${year}-${month}-${day}`;
		const formattedTime = `${hours}:${minutes}:${seconds}`;

		return `${formattedDate} ${formattedTime}`;
	}
	const indexOfLastEntry = currentPage * entriesPerPage;
	const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
	const currentEntries = report.slice(indexOfFirstEntry, indexOfLastEntry);

	const totalPages = Math.ceil(report.length / entriesPerPage);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/">Home</Link>
						</li>
						<li className="breadcrumb-item active">Yesterday Click Report</li>
					</ul>
					<h1 className="page-header mb-0">Yesterday Click Report</h1>
				</div>
				<div className="ms-auto">
				</div>
			</div>

			<div className="card border-0">
				<div className="tab-content p-3">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-3">
						</div>
						<div className="table-responsive mb-3">
							<table className="table table-hover table-panel text-nowrap align-middle mb-0">
								<thead>
									<tr>
										<th>Name</th>
										<th>Date</th>
										<th>Windows</th>
										<th>MAC</th>
										<th>Android</th>
										<th>iPhone</th>
										<th>Total Clicks</th>
										<th>Actions </th>
									</tr>
								</thead>
								<tbody>
									{currentEntries.map((report) => (
										<tr key={report.id}>
											<td>{report.FullName}</td>
											<td>{formatDateTime(report.CurrentDate)}</td>
											<td>{report.Windows}</td>
											<td>{report.MAC}</td>
											<td>{report.iPhone}</td>
											<td>{report.Android}</td>
											<td>{report.TotalClick}</td>
											<td>
												<span>
													<Link >
														<i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
													</Link>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="d-md-flex align-items-center">
							<div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
								Showing{" "}
								{report.length === 0
									? indexOfFirstEntry
									: indexOfFirstEntry + 1}{" "}
								to{" "}
								{indexOfLastEntry > report.length
									? report.length
									: indexOfLastEntry}{" "}
								of {report.length} entries
							</div>
							<ul className="pagination mb-0 justify-content-center">
								<li
									className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
								>
									<button
										className="page-link"
										onClick={() => handlePageChange(currentPage - 1)}
									>
										Previous
									</button>
								</li>
								{[...Array(totalPages)].map((_, index) => (
									<li
										key={index}
										className={`page-item ${index + 1 === currentPage ? "active" : ""
											}`}
									>
										<button
											className="page-link"
											onClick={() => handlePageChange(index + 1)}
										>
											{index + 1}
										</button>
									</li>
								))}
								<li
									className={`page-item ${currentPage === totalPages ? "disabled" : ""
										}`}
								>
									<button
										className="page-link"
										onClick={() => handlePageChange(currentPage + 1)}
									>
										Next
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default YesterdayTracking;
