import React from 'react';
import { Redirect } from 'react-router-dom';
import Dashboard from '../pages/dashboard/dashboard.js';
import Publisher from '../pages/publisher/publisher.js';
import LandingDomain from '../pages/LandingDomain/landingDomain.js';
import AdURL from '../pages/AdUrl/AdUrl.js';
import RedirectDomain from '../pages/redirectDomain/redirectDomain.js';
import TodayTracking from '../pages/report/today-Tracking.js';
import YesterdayTracking from '../pages/report/yesterday-Tracking.js';
import SearchTracking from '../pages/report/search-Tracking.js';
import Staff from '../pages/staff/staff.js';
import UrTodayTracking from '../pages/uniqueReport/ur-today-Tracking.js';
import UrYesterdayTracking from '../pages/uniqueReport/ur-yesterday-Tracking.js';
import UrSearchTracking from '../pages/uniqueReport/ur-search-Tracking.js';
import CreateStaff from '../pages/staff/createStaff.js';
import UpdateStaff from '../pages/staff/UpdateStaff.js';
import CreatePublisher from '../pages/publisher/createPublisher.js';
import UpdatePublisher from '../pages/publisher/updatePublisher.js';
import CreateLD from '../pages/LandingDomain/CreateLD.js';
import UpdateLD from '../pages/LandingDomain/UpdateLD.js';
import CreateRD from '../pages/redirectDomain/CreadRD.js';
import UpdateRD from '../pages/redirectDomain/UpdateRD.js';
import PublishersWEB from '../pages/publisher/publisherWeb.js';
import PublisherButtons from '../pages/publisher/publisherButton.js';
import PublisherPassword from '../pages/publisher/publisherPassword.js';
import PublisherReport from '../pages/publisher/publisherReport.js';
import Software from '../pages/software/software.js';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard'/>
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: () => <Dashboard />
  },  
  {
    path: '/publisher',
    title: 'Publisher',
    component: () => <Publisher />
  }, 
  {
    path: '/createPublisher',
    title: 'Create Publisher',
    component: () => <CreatePublisher />
  }, 
  {
    path: '/updatePublisher/:id',
    title: 'Update Publisher',
    component: () => <UpdatePublisher />
  }, 
  {
    path: '/publisherWeb/:id',
    title: 'Publisher Websites',
    component: () => <PublishersWEB />
  }, 
  {
    path: '/publisherPassword/:id',
    title: 'Publisher Password',
    component: () => <PublisherPassword />
  }, 
  {
    path: '/publisherReport/:id',
    title: 'Publisher Report',
    component: () => <PublisherReport />
  }, 
  {
    path: '/software',
    title: 'SoftWare',
    component: () => <Software />
  }, 
  {
    path: '/publisherButton/:id',
    title: 'Publisher Buttons',
    component: () => <PublisherButtons />
  }, 
  {
    path: '/adURL',
    title: 'Ad URL',
    component: () => <AdURL />
  }, 
  {
    path: '/landingDomain',
    title: 'Landing Domain',
    component: () => <LandingDomain/>
  }, 
  {
    path: '/createLD',
    title: 'Create Landing Domain',
    component: () => <CreateLD/>
  }, 
  {
    path: '/updateLD/:id',
    title: 'Update Landing Domain',
    component: () => <UpdateLD/>
  }, 
  {
    path: '/RedirectDomain',
    title: 'Redirect Domain',
    component: () => <RedirectDomain/>
  }, 
  {
    path: '/createRD',
    title: 'Create Redirect Domain',
    component: () => <CreateRD/>
  }, 
  {
    path: '/updateRD/:id',
    title: 'Update Redirect Domain',
    component: () => <UpdateRD/>
  }, 
  {
    path: '/today-Tracking',
    title: 'Today Tracking',
    component: () => <TodayTracking/>
  }, 
  {
    path: '/yesterday-Tracking',
    title: 'Yesterday Tracking',
    component: () => <YesterdayTracking/>
  }, 
  {
    path: '/search-Tracking',
    title: 'Search Tracking',
    component: () => <SearchTracking/>
  }, 
  {
    path: '/ur-today-Tracking',
    title: 'Today Tracking',
    component: () => <UrTodayTracking/>
  }, 
  {
    path: '/ur-yesterday-Tracking',
    title: 'Yesterday Tracking',
    component: () => <UrYesterdayTracking/>
  }, 
  {
    path: '/ur-search-Tracking',
    title: 'Search Tracking',
    component: () => <UrSearchTracking/>
  }, 
  {
    path: '/staff',
    title: 'Users',
    component: () => <Staff/>
  }, 
  {
    path: '/createStaff',
    title: 'Create Staff',
    component: () => <CreateStaff/>
  }, 
  {
    path: '/UpdateStaff/:id',
    title: 'Update Staff',
    component: () => <UpdateStaff/>
  }, 
  {
    path: '*',
    component: () => <Redirect to='/dashboard' />
  } 
];


export default routes;