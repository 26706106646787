import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import { enqueueSnackbar } from "notistack";

const UpdateStaff = () => {
  const history = useHistory();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    UserName: "",
    isAdmin: false,
    status: false,
  });

  useEffect(() => {

    const FetchStaffDetail = async () => {
      try {
        const response = await axiosInstance.get(
          "User/GetStaff",
          {
            params: { id: id },
          }
        );
        const staff = response.data;

        setFormData({
          fullName: staff.FullName || "",
          email: staff.Email || "",
          UserName: staff.UserName || "",
          isAdmin: staff.IsAdmin || false,
          status: staff.Status || false,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }

        console.error("Error Update staff:", error);
        let errorMessage = "An error occurred.";
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 3000,
        })
        console.error("Error fetching staff:", error);
      }
    };

    FetchStaffDetail();
  }, [id]);



  const handleChange = (e) => {
    const { id, value, type, checked, name } = e.target;
    if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value === "true",
      });
    } else {
      setFormData({
        ...formData,
        [id]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(
        `User/UpdateStaff`,
        {
          FullName: formData.fullName,
          IsAdmin: formData.isAdmin,
          UserName: formData.UserName,
          Status: formData.status,
        },
        {
          params: { id: id },
        }
      );

      history.push("/staff");
    } catch (error) {

      if (error.response && error.response.status === 401) {

        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }

      console.error("Error creating staff:", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="fs-13px">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/staff"> Staff</Link>
              </li>
              <li className="breadcrumb-item Active"> Update Staff </li>
            </ul>
            <h1 className="page-header mb-0">Update Staff</h1>
          </div>
          <div className="ms-auto">
            <button
              type="submit"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
              Submit
            </button>
          </div>
        </div>
        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Full Name"
                  id="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                <label
                  htmlFor="fullName"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Full Name
                </label>
              </div>

              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Email Address"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  readOnly={true}
                />
                <label
                  htmlFor="emailAddress"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Email Address
                </label>
              </div>

              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="User Name"
                  id="UserName"
                  value={formData.UserName}
                  onChange={handleChange}
                  readOnly={true}
                />
                <label
                  htmlFor="UserName"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  User Name
                </label>
              </div>

              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="col-md-12 d-flex align-items-center">
                    <div className="form-check mb-15px me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="status"
                        checked={formData.status}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="status">
                        Status
                      </label>
                    </div>
                    <div className="form-check mb-15px me-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="isAdmin"
                        id="isAdmin"
                        value="true"
                        checked={formData.isAdmin === true}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="isAdmin">
                        Admin
                      </label>
                    </div>
                    <div className="form-check mb-15px">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="isAdmin"
                        id="isUser"
                        value="false"
                        checked={formData.isAdmin === false}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="isUser">
                        User
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateStaff;
