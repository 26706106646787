import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import Modal from "react-modal";

const Software = () => {
  const [publishers, setPublishers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [entriesPerPage] = useState(10);
  const [modalData, setModalData] = useState({
    URL: "",
    Password: "",
    Status: true,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const FetchStaff = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`Software`);
      const users = response.data;
      setPublishers(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  }, []);

  useEffect(() => {
    FetchStaff();
  }, [FetchStaff]);

  // Calculate the data for the current page
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = publishers.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(publishers.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DeleteUser = async (id) => {
    try {
      await axiosInstance.delete(`Software/${id}`);
      const updatedStaffs = publishers.filter((staff) => staff.id !== id);
      setPublishers(updatedStaffs);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  };

  const openModal = (data = null) => {
    if (data) {
      setIsEditing(true);
      setEditId(data.id);
      setModalData(data);
    } else {
      setIsEditing(false);
      setEditId(null);
      setModalData({
        URL: "",
        Password: "",
        Status: true,
      });
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { id, name, value, type, checked } = e.target;

    if (type === "radio") {
      setModalData({
        ...modalData,
        [name]: value === "true",
      });
    } else {
      setModalData({
        ...modalData,
        [id]: type === "checkbox" ? checked : value,
      });
    }
    console.log(modalData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axiosInstance.put(`Software/${editId}`, modalData);
      } else {
        await axiosInstance.post(`Software`, modalData);
      }
      FetchStaff();
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/software"> SoftWare</Link>
            </li>
            <li className="breadcrumb-item Active"> SoftWare </li>
          </ul>
          <h1 className="page-header mb-0"> SoftWare</h1>
        </div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-success btn-rounded px-4 rounded-pill"
            onClick={() => openModal()}
          >
            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
            Add
          </button>
        </div>
      </div>
      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>URL</th>
                    <th>Password</th>
                    <th>Status</th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((web) => (
                    <tr key={web.id}>
                      <td>{web.URL}</td>
                      <td>{web.Password}</td>

                      <td>
                        {web.Status ? (
                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Active
                          </span>
                        ) : (
                          <span className="badge border border-danger text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Pending
                          </span>
                        )}
                      </td>
                      <td>
                        <span onClick={() => openModal(web)}>
                          <i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          <i
                            onClick={() => DeleteUser(web.id)}
                            className="fa fa-trash fs-13px fa-fw me-5px"
                          ></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing{" "}
                {publishers.length === 0
                  ? indexOfFirstEntry
                  : indexOfFirstEntry + 1}{" "}
                to{" "}
                {indexOfLastEntry > publishers.length
                  ? publishers.length
                  : indexOfLastEntry}{" "}
                of {publishers.length} entries
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "800px",
            height: "500px",
            margin: "auto",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            padding: "20px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <form onSubmit={handleSubmit} className="fs-13px">
          <div className="d-flex align-items-center mb-3">
            <div>
              <h2> {isEditing ? "Edit SoftWare" : "Add SoftWare"}</h2>
            </div>
            <div className="ms-auto">
              <button
                type="submit"
                className="btn btn-success btn-rounded px-4 rounded-pill"
              >
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                Submit
              </button>
            </div>
          </div>
          <div className="card border-0">
            <div className="tab-content p-3">
              <div className="tab-pane fade show active" id="allTab">
                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="URL"
                    id="URL"
                    name="URL"
                    value={modalData.URL}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="URL"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    URL
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="Password"
                    id="Password"
                    name="Password"
                    value={modalData.Password}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="Password"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Password
                  </label>
                </div>

                <div className="row">
                  <div className="col-md-12 d-flex align-items-center">
                    <div className="col-md-12 d-flex align-items-center">
                      <div className="form-check mb-15px me-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Status"
                          name="Status"
                          checked={modalData.Status}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="status">
                          Status
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Software;
