import React from 'react';
import { Redirect } from 'react-router-dom';
import Login from './../pages/user/login.js';
 
const UnauthorizeRoute = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/user/login'/>
  },
  {
    path: '/user/login',
    title: 'Login',
    component: () => <Login />,
  },
 
  {
    path: '*',
    component: () => <Redirect to='/user/login' />
  }
];


export default UnauthorizeRoute;