import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import Modal from "react-modal";

const PublisherButtons = () => {
  const { id } = useParams();
  const [publishers, setPublishers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [targetingSites, setTargetingSites] = useState([]);
  const [entriesPerPage] = useState(10);
  const [buttonData, setButtonData] = useState({
    ButtonName: "Button 1",
    ButtonLabel: "Button",
    PublisherWebsite: 0,
    FontSize: "16px",
    BorderRadius: "5px",
    ButtonWidth: "40%",
    FontColor: "#000000",
    BackgroundColor: "#ffffff",
    FontWeight: "normal",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const FetchButtons = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`PubButton/GetAll/${id}`);
      const users = response.data;
      setPublishers(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  }, [id]);

  useEffect(() => {
    FetchButtons();
  }, [FetchButtons]);

  // Calculate the data for the current page
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = publishers.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(publishers.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DeleteUser = async (id) => {
    try {
      await axiosInstance.delete(`PubButton/Delete/${id}`);
      const updatedStaffs = publishers.filter((staff) => staff.id !== id);
      setPublishers(updatedStaffs);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  };

  const openModal =async (data = null) => {

    try {
      const response = await axiosInstance.get(`PubWebsite/GetAll/${id}`);
      const users = response.data;
      setTargetingSites(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching Website:", error);
    }
    console.log(data);
    if (data) {
      setIsEditing(true);
      setEditId(data.id);
      setButtonData(data);
    } else {
      setIsEditing(false);
      setEditId(null);
      setButtonData({
        ButtonName: "Button 1",
        ButtonLabel: "Button",
        PublisherWebsite: 0,
        FontSize: "16",
        BorderRadius: "5",
        ButtonWidth: "40",
        FontColor: "#000000",
        BackgroundColor: "#ffffff",
        FontWeight: "normal",
      });
    }
    setModalIsOpen(true);

   

  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setButtonData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axiosInstance.put(`PubButton/Update/${editId}`, buttonData);
      } else {
        await axiosInstance.post(`PubButton/Create`, {
          ...buttonData,
          PublisherID: id,
        });
      }
      FetchButtons();
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleCopy = () => {
    const textarea = document.getElementById("head");
    textarea.select();
    document.execCommand("copy");
    alert("Code copied to clipboard!");
  };

  const handleBodyCopy = () => {
    const textarea = document.getElementById("body");
    textarea.select();
    document.execCommand("copy");
    alert("Code copied to clipboard!");
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/publisher"> Publisher</Link>
            </li>
            <li className="breadcrumb-item Active"> Publisher Buttons </li>
          </ul>
          <h1 className="page-header mb-0"> Publisher Buttons</h1>
        </div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-success btn-rounded px-4 rounded-pill"
            onClick={() => openModal()}
          >
            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
            Add
          </button>
        </div>
      </div>
      <div className="card border-0">
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
          <li className="nav-item me-2">
            <Link className="nav-link px-2">General</Link>
          </li>
          <li className="nav-item me-2">
            <Link to={"/publisherPassword/" + id} className="nav-link px-2">
              Password
            </Link>
          </li>
          <li className="nav-item me-2">
            <Link to={"/publisherWeb/" + id} className="nav-link px-2">
              Websites
            </Link>
          </li>
          <li className="nav-item me-2">
            <Link
              to={"/publisherButton/" + id}
              className="nav-link px-2 active"
            >
              Buttons
            </Link>
          </li>
          <li className="nav-item me-2"><Link to={"/publisherReport/" + id} className="nav-link px-2">Report</Link></li>
        </ul> 
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>Button Name</th>
                    <th>Button Label</th>
                    <th>Border Radius</th>
                    <th>Button Width</th>
                    <th>Font Weight</th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((web) => (
                    <tr key={web.id}>
                      <td>{web.ButtonName}</td>
                      <td>{web.ButtonLabel}</td>
                      <td>{web.BorderRadius}</td>
                      <td>{web.ButtonWidth}</td>
                      <td>{web.FontWeight}</td>
                      <td>
                        <span onClick={() => openModal(web)}>
                          <i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          <i
                            onClick={() => DeleteUser(web.id)}
                            className="fa fa-trash fs-13px fa-fw me-5px"
                          ></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing{" "}
                {publishers.length === 0
                  ? indexOfFirstEntry
                  : indexOfFirstEntry + 1}{" "}
                to{" "}
                {indexOfLastEntry > publishers.length
                  ? publishers.length
                  : indexOfLastEntry}{" "}
                of {publishers.length} entries
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "800px",
            height: "500px",
            margin: "auto",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            padding: "20px",
          },
          overlay: {
            BackgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <form onSubmit={handleSubmit} className="fs-13px">
          <div className="d-flex align-items-center mb-3">
            <div>
              <h2>
                {" "}
                {isEditing ? "Edit Publisher Button" : "Add Publisher Button"}
              </h2>
            </div>
            <div className="ms-auto">
              <button
                type="submit"
                className="btn btn-success btn-rounded px-4 rounded-pill"
              >
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                Submit
              </button>
              {"    "}
              <button
              onClick={() => {closeModal()}}
                className="btn btn-info btn-rounded px-4 rounded-pill"
              >
              
                Cancel
              </button>
            </div>
          </div>
          <div className="card border-0">
            <div className="tab-content p-3">
              <div className="tab-pane fade show active" id="allTab">
                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="button Name"
                    id="ButtonName"
                    name="ButtonName"
                    value={buttonData.ButtonName}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="ButtonName"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    button Name
                  </label>
                </div>
                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="button Label"
                    id="ButtonLabel"
                    name="ButtonLabel"
                    value={buttonData.ButtonLabel}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="ButtonLabel"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    button Label
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <select
                    className="form-control h-45px fs-13px"
                    id="PublisherWebsite"
                    name="PublisherWebsite"
                    value={buttonData.PublisherWebsite}
                    onChange={handleChange}
                  >
                    <option value={0}>Select Targeting Site</option>
                    {targetingSites.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.SiteName}
                      </option>
                    ))}
                  </select>
                  <label
                    htmlFor="PublisherWebsite"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Targeting Site
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    className="form-control h-45px fs-13px"
                    placeholder="font Size"
                    id="FontSize"
                    name="FontSize"
                    value={buttonData.FontSize}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="FontSize"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Font Size (PX)
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    className="form-control h-45px fs-13px"
                    placeholder="font Size"
                    id="BorderRadius"
                    name="BorderRadius"
                    value={buttonData.BorderRadius}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="BorderRadius"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Border Radius(PX)
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    className="form-control h-45px fs-13px"
                    placeholder="Button Width"
                    id="ButtonWidth"
                    name="ButtonWidth"
                    value={buttonData.ButtonWidth}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="ButtonWidth"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Button Width(%)
                  </label>
                </div>

                <div className="mb-15px">
                  <label
                    htmlFor="FontColor"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Font Color
                  </label>
                  <input
                    type="color"
                    className="form-control h-45px fs-13px"
                    placeholder="font Color"
                    id="FontColor"
                    name="FontColor"
                    value={buttonData.FontColor}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-15px">
                  <label
                    htmlFor="BackgroundColor"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Background Color
                  </label>
                  <input
                    type="color"
                    className="form-control h-45px fs-13px"
                    placeholder="background Color"
                    id="BackgroundColor"
                    name="BackgroundColor"
                    value={buttonData.BackgroundColor}
                    onChange={handleChange}
                  />
                </div>
                <div className="row">
                  <label className="align-items-center fs-13px text-gray-600">
                    Font Weight
                  </label>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex align-items-center">
                    <div className="col-md-12 d-flex align-items-center">
                      <div className="form-check mb-15px me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="FontWeight"
                          value="normal"
                          id="fontNormal"
                          checked={buttonData.FontWeight === "normal"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="fontNormal"
                        >
                          Normal
                        </label>
                      </div>

                      <div className="form-check mb-15px me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="FontWeight"
                          id="boldWeight"
                          value="bold"
                          checked={buttonData.FontWeight === "bold"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          style={{ FontWeight: "bold" }}
                          htmlFor="boldWeight"
                        >
                          Bold
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <h3>Button Preview</h3>
        <div
          className="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <button
            style={{
              fontSize: `${buttonData.FontSize}px`,
              borderRadius: `${buttonData.BorderRadius}px`,
              color: buttonData.FontColor,
              width: `${buttonData.ButtonWidth}%`,
              backgroundColor: buttonData.BackgroundColor,
              fontWeight: buttonData.FontWeight,
            }}
          >
            {buttonData.ButtonLabel}
          </button>
        </div>

        <h4>
          Head{" "}
          <i
            className="fa fa-copy"
            onClick={handleCopy}
            style={{ cursor: "pointer" }}
          ></i>{" "}
        </h4>

        <div className="form-floating mb-15px">
          <input
            className="form-control"
            id="head"
            value={`<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>`}
            readOnly
          ></input>
        </div>
        <h4>
          Body{" "}
          <i
            className="fa fa-copy"
            onClick={handleBodyCopy}
            style={{ cursor: "pointer" }}
          ></i>{" "}
        </h4>
        <div className="form-floating mb-15px">
          <textarea
            className="form-control"
            id="body"
            style={{ height: 350 }}
            value={`<center>
                        <style>
                          .button {
                            background-color: ${buttonData.BackgroundColor};
                            border: none;
                            color: ${buttonData.FontColor};
                            padding: 15px 32px;
                            text-align: center;
                            text-decoration: none;
                            display: inline-block;
                            font-size: ${buttonData.FontSize}px;
                            margin: 4px 2px;
                            cursor: pointer;
                            -webkit-transition-duration: 0.4s;  
                            transition-duration: 0.4s;
                          }
                          .button:hover {
                            box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
                          }
                        </style>
                        <button onclick="ButtonClick(${buttonData.PublisherID});" style="font-weight: ${buttonData.FontWeight}; font-size: ${buttonData.FontSize}px; color: ${buttonData.FontColor}; background-color: ${buttonData.BackgroundColor}; height: 40px; border-radius: ${buttonData.BorderRadius}px; padding: 0px 20px; border-color: transparent;">${buttonData.ButtonLabel}</button>
                     </center>
                  <script>  
                  function ButtonClick(publisherId)
                     { 
                         var baseUrl  = "https://backend.clickperinstall.com/"; 
                          $.get(baseUrl +"LandingDomain/GetByPosition?position=1", function(data, status){
                           		  let redirectUrl = data.AdUrl; 
                                  if (!redirectUrl.startsWith("http"))
                                      redirectUrl = "https://" + redirectUrl; 
                                  window.open(redirectUrl+ '?position=1&pub='+publisherId);
                                }); 
                      } 
                  </script>`}
            readOnly
          ></textarea>
        </div>
      </Modal>
    </div>
  );
};

export default PublisherButtons;
