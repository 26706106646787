import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";

const UpdatePublisher = () => {
  const history = useHistory();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    status: false,
  });

  const FetchStaffDetail = useCallback( async () => {
    try {
      const response = await axiosInstance.get(`Publisher/GetPublisher/${id}`);
      const staff = response.data;

      setFormData({
        fullName: staff.FullName || "",
        email: staff.Email || "",
        status: staff.Status || false,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  },[id]);

  useEffect(() => {
    FetchStaffDetail();
  }, [FetchStaffDetail]);

  const handleChange = (e) => {
    const { id, value, type, checked, name } = e.target;
    if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value === "true",
      });
    } else {
      setFormData({
        ...formData,
        [id]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(
        `Publisher/UpdatePublisher/${id}`,
        {
          FullName: formData.fullName,
          Status: formData.status,
        }
      );

      history.push("/publisher");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }

      console.error("Error creating publisher:", error);
    }
  };
  return (
    <div>
 
      <form onSubmit={handleSubmit} className="fs-13px">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/publisher"> Publisher</Link>
              </li>
              <li className="breadcrumb-item Active"> Update Publisher </li>
            </ul>
            <h1 className="page-header mb-0">Update Publisher</h1>
          </div>
          <div className="ms-auto">
            <button
              type="submit"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
              Submit
            </button>
          </div>
        </div>
        <div className="card border-0">
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
						<li className="nav-item me-2"><Link className="nav-link active px-2">General</Link></li>
						<li className="nav-item me-2"><Link to={"/publisherPassword/" + id} className="nav-link px-2">Password</Link></li>
						<li className="nav-item me-2"><Link to={"/publisherWeb/" + id} className="nav-link px-2">Websites</Link></li>
						<li className="nav-item me-2"><Link to={"/publisherButton/" + id} className="nav-link px-2">Buttons</Link></li>
            <li className="nav-item me-2"><Link to={"/publisherReport/" + id} className="nav-link px-2">Report</Link></li>
					</ul>
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Full Name"
                  id="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
                <label
                  htmlFor="fullName"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Full Name
                </label>
              </div>

              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Email Address"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  readOnly={true}
                />
                <label
                  htmlFor="emailAddress"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Email Address
                </label>
              </div>

              <div className="row">
                <div className="col-md-12 d-flex align-items-center">
                  <div className="col-md-12 d-flex align-items-center">
                    <div className="form-check mb-15px me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="status"
                        checked={formData.status}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="status">
                        Status
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
     
    </div>
  );
};

export default UpdatePublisher;
