import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import Modal from "react-modal";

const PublisherWebsites = () => {
  const { id } = useParams();
  const [publishers, setPublishers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [entriesPerPage] = useState(10);
  const [modalData, setModalData] = useState({
    Category: "",
    Protocol: "",
    SiteName: "",
    SiteTitle: "",
    SiteKeyword: "",
    SiteDescription: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const FetchStaff = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`PubWebsite/GetAll/${id}`);
      const users = response.data;
      setPublishers(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  }, [id]);

  useEffect(() => {
    FetchStaff();
  }, [FetchStaff]);

  // Calculate the data for the current page
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = publishers.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(publishers.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DeleteUser = async (id) => {
    try {
      await axiosInstance.delete(`PubWebsite/Delete/${id}`);
      const updatedStaffs = publishers.filter((staff) => staff.id !== id);
      setPublishers(updatedStaffs);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      console.error("Error fetching staff:", error);
    }
  };

  const openModal = (data = null) => {
    if (data) {
      setIsEditing(true);
      setEditId(data.id);
      setModalData(data);
    } else {
      setIsEditing(false);
      setEditId(null);
      setModalData({
        Category: "",
        Protocol: "",
        SiteName: "",
        SiteTitle: "",
        SiteKeyword: "",
        SiteDescription: "",
      });
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevState) => ({ ...prevState, [name]: value }));
    console.log(modalData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axiosInstance.put(`PubWebsite/Update/${editId}`, modalData);
      } else {
        await axiosInstance.post(`PubWebsite/Create`, {
          ...modalData,
          PublisherID: id,
        });
      }
      FetchStaff();
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/publisher"> Publisher</Link>
            </li>
            <li className="breadcrumb-item Active"> Publisher Websites </li>
          </ul>
          <h1 className="page-header mb-0"> Publisher Websites</h1>
        </div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-success btn-rounded px-4 rounded-pill"
            onClick={() => openModal()}
          >
            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
            Add
          </button>
        </div>
      </div>
      <div className="card border-0">
        <ul className="nav nav-tabs nav-tabs-v2 px-3">
          <li className="nav-item me-2">
            <Link className="nav-link px-2">General</Link>
          </li>
          <li className="nav-item me-2">
            <Link to={"/publisherPassword/" + id} className="nav-link px-2">
              Password
            </Link>
          </li>
          <li className="nav-item me-2">
            <Link to={"/publisherWeb/" + id} className="nav-link px-2 active">
              Websites
            </Link>
          </li>
          <li className="nav-item me-2">
            <Link to={"/publisherButton/" + id} className="nav-link px-2">
              Buttons
            </Link>
          </li>
          <li className="nav-item me-2"><Link to={"/publisherReport/" + id} className="nav-link px-2">Report</Link></li>
        </ul>
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Protocol</th>
                    <th>Site Name</th>
                    <th>Site Title</th>
                    <th>Site Keyword</th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((web) => (
                    <tr key={web.id}>
                      <td>{web.Category}</td>
                      <td>{web.Protocol}</td>
                      <td>{web.SiteName}</td>
                      <td>{web.SiteTitle}</td>
                      <td>{web.SiteKeyword}</td>
                      <td>
                        <span onClick={() => openModal(web)}>
                          <i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          <i
                            onClick={() => DeleteUser(web.id)}
                            className="fa fa-trash fs-13px fa-fw me-5px"
                          ></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing{" "}
                {publishers.length === 0
                  ? indexOfFirstEntry
                  : indexOfFirstEntry + 1}{" "}
                to{" "}
                {indexOfLastEntry > publishers.length
                  ? publishers.length
                  : indexOfLastEntry}{" "}
                of {publishers.length} entries
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${index + 1 === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "800px",
            height: "500px",
            margin: "auto",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            padding: "20px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <form onSubmit={handleSubmit} className="fs-13px">
          <div className="d-flex align-items-center mb-3">
            <div>
              <h2>
                {" "}
                {isEditing ? "Edit Publisher Website" : "Add Publisher Website"}
              </h2>
            </div>
            <div className="ms-auto">
              <button
                type="submit"
                className="btn btn-success btn-rounded px-4 rounded-pill"
              >
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                Submit
              </button>
            </div>
          </div>
          <div className="card border-0">
            <div className="tab-content p-3">
              <div className="tab-pane fade show active" id="allTab">

                <div className="form-floating mb-15px">
                  <select
                    className="form-control h-45px fs-13px"
                    id="Category"
                    name="Category"
                    value={modalData.Category}
                    onChange={handleChange}
                  >
                    <option value="" >Select Category</option>
                    <option value="Torrent ADS">Torrent ADS</option>
                    <option value="Mac Only">Mac Only</option>
                    <option value="Game">Game</option>
                    <option value="High CPC ads">High CPC ads</option>
                    <option value="Crack Software's / warez">Crack Software's / warez</option>
                    <option value="Google Safe Ads">Google Safe Ads</option>
                    <option value="Google Safe Ads">Zip Installer</option>
                  </select>
                  <label
                    htmlFor="Category"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Category
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <select
                    className="form-control h-45px fs-13px"
                    id="Protocol"
                    name="Protocol"
                    value={modalData.Protocol}
                    onChange={handleChange}
                  >
                    <option value="" >Select Protocol</option>
                    <option value="http">http</option>
                    <option value="https">https</option>
                    {/* Add more options as needed */}
                  </select>
                  <label
                    htmlFor="Protocol"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Protocol
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="Site Name"
                    id="SiteName"
                    name="SiteName"
                    value={modalData.SiteName}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="SiteName"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Site Name
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="Site Title"
                    id="SiteTitle"
                    name="SiteTitle"
                    value={modalData.SiteTitle}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="SiteTitle"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Site Title
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="Site Keyword"
                    id="SiteKeyword"
                    name="SiteKeyword"
                    value={modalData.SiteKeyword}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="SiteKeyword"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Site Keyword
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="text"
                    className="form-control h-45px fs-13px"
                    placeholder="Site Description"
                    id="SiteDescription"
                    name="SiteDescription"
                    value={modalData.SiteDescription}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="SiteDescription"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Site Description
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default PublisherWebsites;
