const Menu = [
  { path: '/dashboard', icon: 'fa-solid fa-chart-line', title: 'Dashboard'},
  { path: '/publisher', icon: 'fa-solid fa-users', title: 'Publisher'},
  { path: '/adURL', icon: 'fa-solid fa-route', title: 'Ad URL'},
  { path: '/landingDomain', icon: 'fa-solid fa-plane-arrival', title: 'Landing Domain'},
  { path: '/RedirectDomain', icon: 'fa fa-sitemap', title: 'Redirect Domain'},
  { path: '/software', icon: 'fa-brands fa-uncharted', title: 'SoftWare'},
  { path: '/report', icon: 'fa-solid fa-clock-rotate-left', title: 'All Report' ,
    children: [
      { path: '/today-Tracking', title: 'Today Tracking' },
      { path: '/yesterday-Tracking', title: 'Yesterday Tracking' },
      { path: '/search-Tracking', title: 'Search Tracking' }
    ]
  },
  // { path: '/uniqueReport', icon: 'fa fa-hdd', title: 'Unique Report' ,
  //   children: [
  //     { path: '/ur-today-Tracking', title: 'Today Tracking' },
  //     { path: '/ur-yesterday-Tracking', title: 'Yesterday Tracking' },
  //     { path: '/ur-search-Tracking', title: 'Search Tracking' }
  //   ]
  // },
  { path: '/staff', icon: 'fa-solid fa-users', title: 'Users',  },
   
]

export default Menu;
